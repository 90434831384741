<script setup>
import { onMounted } from 'vue';
import { useRouter, useRoute, useNuxtApp } from 'nuxt/app';
import { useFavoriteStore } from '~/stores/favorite.store';
import { useUserStore } from '~/stores/user.store';

const route = useRoute();
const router = useRouter();
const favoriteStore = useFavoriteStore();
const userStore = useUserStore();
const localePath = useLocalePath();
const {$toast} = useNuxtApp()

const favourId = ref(null)
const favoriteName = ref('')
const isAuthorizedModalOpen = ref(false)
const isFavoriteModalOpen = ref(false)
const userFavorites = computed(() => favoriteStore.userFavoriteSearchGetter());

onMounted(async () => {
  await checkFavoriteSearchExist()
  await fetchUserFavoriteSearches()
})
watch(() => favourId.value, async () => await checkFavoriteSearchExist());

const fetchUserFavoriteSearches = async () => {
  await favoriteStore.fetchUserFavoriteSearches()
    .catch(err => userFavorites.value = [])
}

const user = computed(() => userStore.userGetter());

const addFavoriteSearch = async () => {
  
  await favoriteStore.addFavoriteSearch({
    name: favoriteName.value,
    user_id: 100,
    url: route.fullPath
  })
    .then((res) => {
      favourId.value = res.id
      isFavoriteModalOpen.value = false
      $toast.addToast({
        title: res.data.message || "Listing Added Successfully",
        timeout: 5000,
        position: 'top-10',
      })
    })
    .catch(err => {
      if (err?.response?.status == 401) {
        return isAuthorizedModalOpen.value = true
      }
      const message = err?.message || 'Something Went Wrong Please Try Again'
      $toast.addToast({ title: message, color: "red" })
    })
}

const removeFromFavorite = async () => {
  if (!favourId.value) {
    return
  }
  await favoriteStore.removeFavoriteSearch(favourId.value)
    .then((res) => {
      favourId.value = null
      $toast.addToast({
        title: res.data.message || "Listing Removed Successfully",
        timeout: 5000,
        position: 'top-10',
      })
    })
    .catch((err) => {
      const message = err?.message || 'Something Went Wrong Please Try Again'
      $toast.addToast({ title: message, color: "red" })
    })
}

const checkOpenAuthOrForm = () => {
  if (user.value) {
    isAuthorizedModalOpen.value = false;
    isFavoriteModalOpen.value = true
  } else {
    isAuthorizedModalOpen.value = true;
    isFavoriteModalOpen.value = false;
  }
}

const checkFavoriteSearchExist = async () => {
  await favoriteStore.checkExistFavoriteSearch({ url: route.fullPath || '' })
    .then((res) => favourId.value = res.data?.id || null)
    .catch(err => favourId.value = null)
}

const authorize = () => {
  router.push({ path: localePath({ name: 'account.login' }) });
  isAuthorizedModalOpen.value = false
}
</script>

<template>
  <div v-if="!favourId"
    class="flex text-gray-500 p-1 rounded hover:bg-gray-100 cursor-pointer"
    @click="checkOpenAuthOrForm()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-7 h-7 text-white">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>
  </div>

  <div v-else
    class="flex text-gray-500 p-1 rounded hover:bg-gray-100 cursor-pointer"
    @click="removeFromFavorite()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="#EDBD29" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-7 h-7 text-yellow-500">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>
  </div>

  <w-modal v-model="isAuthorizedModalOpen" :transition="true" class="w-full flex flex-col items-center justify-center" :ui="{ container: 'items-center' }">
    <div class="p-8 text-center flex flex-col gap-3">
      <h3 class="text-gray-900 text-2xl my-3">{{ $t("client.you_should_login_or_register_for_add_favorite") }}
      </h3>
    </div>
    <div class="w-full flex items-center justify-center self-center ">
      <w-button class="text-lg text-white bg-indigo-900 p-3 rounded-md text-center border-0 m-5 block hover:bg-indigo-800"
        @click="authorize">
        {{ $t('client.login_register') }}
      </w-button>
    </div>
  </w-modal>

  <w-modal v-model="isFavoriteModalOpen" :transition="true" class="w-full flex flex-col items-center justify-center" :ui="{ container: 'items-center' }">
    <div class="px-8 text-center flex flex-col gap-3">
      <h3 class="text-gray-900 text-2xl my-3">{{ $t("client.add_to_favorite") }}</h3>
    </div>
    <w-input class="border-0 m-5 w-full mx-auto" type="text" @update:value="(newVal)=> favoriteName=newVal" />
 
    <div class="w-full flex items-center justify-center self-center ">
        <w-button class="text-lg  text-white bg-indigo-900 p-3 rounded-md text-center border-0 m-5 block hover:bg-indigo-800"
        @click="addFavoriteSearch()">
        {{ $t('client.add') }}
      </w-button>
    </div>
  </w-modal>
</template>